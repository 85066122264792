/*
 *
 * TODO
 * PROD BASE API ==> https://api.investclubglobal.com
 * DEV  BASE API ==> http://dev-api.investclubglobal.com
 *
*/

export const environment = {
  production: false,
  baseUrl: 'https://dev-api.investclubglobal.com',
  // baseUrl: 'http://localhost:3000',
};
