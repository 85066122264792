import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { StorageService } from '../../services/storage/storage.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ToasterService } from '../../services/app-services/toaster/toaster.service';
import { AuthService } from '../../services/app-services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private storageService: StorageService,
    private toaster: ToasterService,
    private authService: AuthService,
    private router: Router
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler, headers?: any): Observable<HttpEvent<unknown>> {

    const token = this.storageService.getToken();
    const isAuthorized = this.storageService.isAuthorized();

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && isAuthorized) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newTokens: any) => {
          this.toaster.showToast('info', '', 'refresh token');
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newTokens.accessToken);
          this.storageService.setToken(newTokens.accessToken);
          this.storageService.setRefreshToken(newTokens.refreshToken);
          return next.handle(this.addToken(request, newTokens.accessToken));
        }),
        catchError(error => {
          this.getTimeOutFn();
          return throwError(error);
        }),
        finalize(() => this.isRefreshing = false)
      );
    } else {
      this.getTimeOutFn();
    }
  }

  private getTimeOutFn() {
    if (!this.router.url.includes('login')) {
      const message = 'YOUR SESSION HAS EXPIRED';
      setTimeout(() => {
        this.storageService.logout();
        this.router.navigateByUrl('/auth/login');
        this.toaster.showToast('danger', message);
      }, 1000);
    }
  }
}

export const HTTP_TOKEN_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpTokenInterceptor,
  multi: true
};
